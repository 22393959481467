.btn {
  border: medium none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  padding: 8px 20px;
  transition: all 0.25s linear 0s;
}
.btn.btn-primary,
.btn.btn-danger,
.btn.btn-default,
.btn.btn-success,
.btn.btn-info,
.btn.btn-warning,
.btn.btn-fb,
.btn.btn-tw {
  font-weight: 600;
  text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
  -o-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
  -webkit-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
  -ms-text-shadow: 0px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0 -1px rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 -1px rgba(0, 0, 0, 0.1) inset;
  -ms-box-shadow: 0 -1px rgba(0, 0, 0, 0.1) inset;
}
.btn.btn-lg {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.471;
  padding: 10px 20px;
}
.btn.btn-md {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
  padding: 6px 14px;
}
.btn.btn-sm {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 11px;
  line-height: 1.5;
  padding: 4px 8px;
}
.btn.btn-xs {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
}
.btn-old {
  border: medium none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  padding: 6px 12px;
  transition: all 0.25s linear 0s;
  -webkit-transition: all 0.25s linear 0s;
  -moz-transition: all 0.25s linear 0s;
  -ms-transition: all 0.25s linear 0s;
  -o-transition: all 0.25s linear 0s;
}
.btn-old.btn-lg {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.471;
  padding: 10px 20px;
}
.btn-old.btn-md {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
  padding: 12px 14px;
}
.btn-old.btn-sm {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 13px;
  line-height: 1.5;
  padding: 4px 8px;
}
.btn-old.btn-xs {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
}
.btn-stroke-dark,
.btn-stroke-light,
.btn-stroke,
.btn-dark,
.search-trigger,
.btn-nobg {
  text-shadow: none !important;
  box-shadow: none !important;
  font-weight: normal;
}
.priceFilterBody .btn {
  padding: 8px 12px;
}
.navbar-right .input-group .btn {
  box-shadow: none;
}
.btn-flat {
  border-radius: 0 !important;
}
.btn-product-flat {
  background: #303030;
  border-radius: 0;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.5px;
  min-width: 130px;
  padding: 7px 15px;
}
.btn-product-flat:hover,
.btn-product-flat:focus,
.btn-product-flat:active,
.btn-product-flat.active {
  background-color: #fff;
  border-color: #fff;
  color: #0f0f0f;
}
.btn-thin {
  border: 1px solid #bdc3c7;
  color: #bdc3c7;
  background: none;
  text-shadow: none;
}
.btn-default {
  background-color: #bdc3c7;
  color: #ffffff;
  text-shadow: none;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active {
  background-color: #cacfd2;
  border-color: #cacfd2;
  color: #ffffff;
}
.open .dropdown-toggle.btn-default {
  background-color: #cacfd2;
  border-color: #cacfd2;
  color: #ffffff;
  background: #a1a6a9;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default.active {
  background-color: #bdc3c7;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary.active {
  background-color: #1abc9c;
}
.btn-dark {
  background-color: #34495e;
  color: #ffffff;
}
.btn-dark:hover,
.btn-dark:focus {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
  color: #ffffff;
}
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
  color: #ffffff;
  background: #2a2a2a;
}
.btn-info {
  background-color: #3498db;
  color: #ffffff;
}
.btn-info:hover,
.btn-info:focus {
  background-color: #5dade2;
  border-color: #5dade2;
  color: #ffffff;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #5dade2;
  border-color: #5dade2;
  color: #ffffff;
  background: #2c81ba;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info.active[disabled],
fieldset[disabled] .btn-info.active {
  background-color: #3498db;
}
.btn-danger {
  background-color: #e74c3c;
  color: #ffffff;
}
.btn-danger:hover,
.btn-danger:focus {
  background-color: #ec7063;
  border-color: #ec7063;
  color: #ffffff;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #ec7063;
  border-color: #ec7063;
  color: #ffffff;
  background: #c44133;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger.active[disabled],
fieldset[disabled] .btn-danger.active {
  background-color: #e74c3c;
}
.btn-success {
  background-color: #2ecc71;
  color: #ffffff;
}
.btn-success:hover,
.btn-success:focus {
  background-color: #58d68d;
  border-color: #58d68d;
  color: #ffffff;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #58d68d;
  border-color: #58d68d;
  color: #ffffff;
  background: #27ad60;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success.active[disabled],
fieldset[disabled] .btn-success.active {
  background-color: #2ecc71;
}
.btn-warning {
  background-color: #f1c40f;
  color: #ffffff;
}
.btn-warning:hover,
.btn-warning:focus {
  background-color: #f5d313;
  border-color: #f5d313;
  color: #ffffff;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #f5d313;
  border-color: #f5d313;
  color: #ffffff;
  background: #cda70d;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning.active[disabled],
fieldset[disabled] .btn-warning.active {
  background-color: #f1c40f;
}
.btn-inverse {
  background-color: #3a3a3a;
  color: #ffffff;
}
.btn-inverse:hover,
.btn-inverse:focus {
  background-color: #454545;
  border-color: #454545;
  color: #ffffff;
}
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  border-color: #454545;
  color: #ffffff;
  background: #303030;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse.active[disabled],
fieldset[disabled] .btn-inverse.active {
  background-color: #34495e;
}
.btn-stroke {
  background: rgba(0, 0, 0, 0);
  font-family: "Oswald", Helvetica Neue, Arial, sans-serif;
  letter-spacing: 2px;
  padding: 15px 20px;
  text-align: center;
  border-radius: 0;
  min-width: 200px;
  text-transform: uppercase;
  border: 2px solid #fff;
  color: #fff !important;
}
.btn-stroke.lite {
  border: 2px solid #ffffff;
  color: #ffffff !important;
}
.btn-stroke.lite:hover {
  background: #ffffff;
  color: #303030 !important;
}
.btn-stroke.dark {
  border: 2px solid #303030;
  color: #303030 !important;
}
.btn-stroke.dark:hover {
  background: #303030;
  color: #ffffff !important;
}
.btn-stroke.thin {
  border-width: 1px;
  font-size: 12px;
  padding-left: 40px;
  padding-right: 40px;
}
.btn-dark {
  background: #303030;
  font-family: "Oswald", Helvetica Neue, Arial, sans-serif;
  letter-spacing: 2px;
  padding: 15px 20px;
  text-align: center;
  border-radius: 0;
  min-width: 200px;
  text-transform: uppercase;
  border: 2px solid #303030;
  color: #fff !important;
}
.btn-dark:hover {
  background: #fff;
  color: #303030 !important;
  border-color: #fff;
}
@media only screen and (max-width: 767px) {
  .btn-dark {
    letter-spacing: 1px;
    padding: 10px 15px;
    min-width: 160px;
  }
}
.btn-gray {
  background: #c8c8c8;
  font-family: "Oswald", Helvetica Neue, Arial, sans-serif;
  letter-spacing: 2px;
  padding: 15px 20px;
  text-align: center;
  border-radius: 0;
  min-width: 200px;
  text-transform: uppercase;
  border: 2px solid #ccc;
  color: #fff !important;
}
.btn-gray:hover {
  background: #fff;
  color: #303030 !important;
  border-color: #fff;
}
@media only screen and (max-width: 767px) {
  .btn-gray {
    letter-spacing: 1px;
    padding: 10px 15px;
    min-width: 160px;
  }
}
.btn-stroke-dark {
  background: rgba(0, 0, 0, 0);
  font-family: "Oswald", Helvetica Neue, Arial, sans-serif;
  letter-spacing: 2px;
  padding: 15px 20px;
  text-align: center;
  border-radius: 0;
  min-width: 200px;
  text-transform: uppercase;
  border: 2px solid #303030;
  color: #303030 !important;
}
.btn-stroke-light {
  background: rgba(0, 0, 0, 0);
  font-family: "Oswald", Helvetica Neue, Arial, sans-serif;
  letter-spacing: 2px;
  padding: 15px 20px;
  text-align: center;
  border-radius: 0;
  min-width: 200px;
  text-transform: uppercase;
  border: 2px solid #ffffff;
  color: #ffffff !important;
}
.btn-stroke-dark:hover {
  background: #303030;
  color: #ffffff !important;
}
.btn-stroke-light:hover {
  background: #ffffff;
  color: #303030 !important;
}
.btn-site {
  padding: 5px 0;
  color: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  display: block;
  text-decoration: none;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
}
.fb_button {
  background: #658ad0;
  color: #ffffff;
  display: block;
  text-align: center;
  text-decoration: none;
}
.btn-fb {
  background: #658ad0;
  color: #ffffff;
}
.fb_button:hover,
.btn-fb:hover {
  background: #4e6fae;
  color: #ffffff;
  text-decoration: none;
}
.btn-tw {
  background: #1dadeb;
  color: #ffffff;
}
.btn-tw:hover {
  background: #35c5ff;
  color: #ffffff;
  text-decoration: none;
}
